<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生医疗保险购买记录
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">医疗保险购买记录</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="toexport">导入</el-button>
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xh"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xm"></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">身份证号</div>
          <el-input size="mini" v-model="sfzh"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;级'"
            class="sm_search_title"
          ></div>
          <el-select
            v-model="nj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item.nj"
              :value="item.nj"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">二级学院</div>
          <el-select
            v-model="bm"
            style="display: block; width: 178px"
            size="mini"
            @change="bmchange"
            clearable
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业名称</div>
          <el-select
            v-model="zy"
            style="display: block; width: 178px"
            size="mini"
            @change="zychange"
            clearable
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级名称</div>
          <el-select
            v-model="bj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 240"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="jfdc" label="缴费档次" width="200">
        </el-table-column>
        <el-table-column prop="jfnd" label="缴费年度" width="200">
        </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="nj" label="年级" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="sfzh" label="身份证号" width="200">
        </el-table-column>
        <el-table-column prop="bmmc" label="二级学院" width="300">
        </el-table-column>
        <el-table-column prop="zymc" label="专业名称" width="350">
        </el-table-column>
        <el-table-column prop="bjmc" label="班级名称" width="300">
        </el-table-column>
        <el-table-column prop="rybh" label="人员编号" width="200">
        </el-table-column>
        <el-table-column prop="jbjg" label="经办机构" width="200">
        </el-table-column>
        <el-table-column prop="zdxm" label="征收项目" width="300">
        </el-table-column>
        <el-table-column prop="zspm" label="征收品目" width="300">
        </el-table-column>
        <el-table-column prop="zszx" label="征收子项" width="300">
        </el-table-column>
        <el-table-column prop="jdxz" label="街道乡镇" width="300">
        </el-table-column>
        <el-table-column prop="sqcz" label="社区村组" width="300">
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="导入购买记录"
      :visible.sync="dialogImportVisible"
      top="5vh"
    >
      <div style="text-align: center">
        <el-upload
          class="upload-demo"
          drag
          :headers="headers"
          :action="uploadurl"
          :multiple="false"
          :on-success="handleSuccess"
          :show-file-list="false"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">请上传excel文件</div>
          </template>
        </el-upload>
        <div>
          <el-link
            type="primary"
            :href="downbaseurl + 'UploadFile/template/gmjl.xlsx'"
            >模板下载</el-link
          >
          <br />
          <el-link type="danger" v-if="downerrurl" :href="downerrurl"
            >下载错误信息</el-link
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getXSIndexData, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getGMJLPageList } from '../../api/ylbx'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
export default {
  data() {
    return {
      dialogFormVisible: false,
      selxh: '',
      keyValue: '',
      xh: '',
      xm: '',
      sfzh: '',
      nj: '',
      njlist: [],
      bm: '',
      bmlist: [],
      zy: '',
      zylist: [],
      bj: '',
      bjlist: [],
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      headers: '',
      uploadurl: baseUrl + 'ylbx/SY_YLBX_GMJL/upload',
      downerrurl: '',
      dialogImportVisible: false,
      downbaseurl: baseUrl,
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    getXSIndexData().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data.njlist
        this.bmlist = res.data.bmlist
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bm }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zy }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zy = ''
      this.bj = ''
      this.getZY()
    },
    zychange() {
      this.bj = ''
      this.getBJ()
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getGMJLPageList({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
          sfzh: this.sfzh,
          nj: this.nj,
          bm: this.bm,
          zy: this.zy,
          bj: this.bj,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.selxh = ''
      } else {
        this.keyValue = currentRow.id
        this.selxh = currentRow.xh
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    toexport() {
      this.dialogImportVisible = true
    },
    todelete() {},
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success(response.message)
        this.dialogImportVisible = false
        this.getDataPageList()
      } else {
        this.$message.error(response.message)
        this.downerrurl = this.downbaseurl + response.data.url
      }
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
