import {
  get, post
} from '../utils/http';

// 获取学生医疗保险购买记录
export function getGMJLPageList(data) {
  return get('ylbx/SY_YLBX_GMJL/GetPageList/', data);
}

// 获取学生理赔记录
export function getLPPageList(data) {
  return get('ylbx/SY_YLBX_LPDJ/GetPageList/', data);
}

// 提交学生理赔记录
export function saveLPJL(data) {
  return post('ylbx/SY_YLBX_LPDJ/Save/', data);
}

// 获取学生理赔记录信息
export function getLPInfo(data) {
  return get('ylbx/SY_YLBX_LPDJ/GetFormData/', data);
}

// 删除学生理赔记录信息
export function deleteInfo(data) {
  return get('ylbx/SY_YLBX_LPDJ/Delete/', data);
}
